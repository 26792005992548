/*
 * @Author: zhao zhaojunlong@live.com
 * @Date: 2023-01-23 23:16:38
 * @LastEditors: zhao zhaojunlong@live.com
 * @LastEditTime: 2023-09-05 15:44:40
 * @FilePath: \echarts-2DMapto3D\js\anhuiCenterPoint.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
var geoCoordMap = {
  网格一组: [119.7195, 41.405],
  网格四组: [119.701, 41.405],
  网格五组: [119.696, 41.3999],
  网格六组: [119.685, 41.4031],
  网格十二组: [119.703, 41.3968],
  网格三组: [119.6997, 41.392],
  网格二组: [119.71, 41.389],
  '网格七、八组': [119.692, 41.385],
  网格十组: [119.673, 41.357],
  网格十一组: [119.641, 41.341],
  部队: [119.673, 41.341],
  网格九组: [119.692, 41.373],
}
var mapdata = [
  {
    name: '网格一组',
    value: 100,
  },
  {
    name: '网格四组',
    value: 120,
  },
  {
    name: '网格五组',
    value: 120,
  },
  {
    name: '网格六组',
    value: 120,
  },
  {
    name: '网格十二组',
    value: 120,
  },
  {
    name: '网格三组',
    value: 120,
  },
  {
    name: '网格二组',
    value: 120,
  },
  {
    name: '网格七、八组',
    value: 120,
  },
  {
    name: '网格十组',
    value: 120,
  },
  {
    name: '网格十一组',
    value: 120,
  },
  {
    name: '部队',
    value: 120,
  },
  {
    name: '网格九组',
    value: 120,
  },
]
//地图编辑的3d线条
var anhuiLine1 = [
  [119.72909052310587, 41.416337026565174],
  [119.72879515279037, 41.41634007741168],
  [119.72786716299807, 41.41619730975132],
  [119.72632051334429, 41.41595936365072],
  [119.72444073914968, 41.415650033719984],
  [119.72322721403675, 41.41495999002829],
  [119.72229922424445, 41.414032000235984],
  [119.72022909316937, 41.41331816193427],
  [119.71861201146979, 41.41292412319175],
  [119.7175055621021, 41.41263858787103],
  [119.7175055621021, 41.41242443638044],
  [119.7179338650832, 41.412103209144696],
  [119.71821940040383, 41.411817673823975],
  [119.71846924380944, 41.41149644658823],
  [119.71893323870563, 41.41121091126751],
  [119.71861201146979, 41.41071122445629],
  [119.71861201146979, 41.41042568913557],
  [119.71839785997929, 41.41017584572997],
  [119.71800524891333, 41.41006876998472],
  [119.7167917238004, 41.41017584572997],
  [119.71611357741371, 41.41063984062607],
  [119.71604219358348, 41.41010446189983],
  [119.71579235017788, 41.41014015381485],
  [119.71297268888604, 41.41006876998472],
  [119.71179485568814, 41.41046138105069],
  [119.70901088631133, 41.410996759777014],
  [119.70861827524536, 41.41085399211666],
  [119.70876104290572, 41.410282921475215],
  [119.70876104290572, 41.4086410933812],
  [119.70744044204746, 41.40860540146609],
  [119.70644106842502, 41.40971185083386],
  [119.70330017989727, 41.40960477508861],
  [119.70287187691628, 41.41010446189983],
  [119.70255064968043, 41.41042568913557],
  [119.70205096286922, 41.410675532541184],
  [119.70044482669032, 41.41085399211666],
  [119.69965960455838, 41.41074691637141],
  [119.69584056964403, 41.410282921475215],
  [119.69348490324822, 41.409533391258385],
  [119.69105785302226, 41.40892662870192],
  [119.68891633811704, 41.40824848231523],
  [119.6865249798062, 41.40785587124926],
  [119.68638221214583, 41.40739187635316],
  [119.68638221214583, 41.406570962306105],
  [119.68688189895705, 41.406570962306105],
  [119.68684620704194, 41.4063211189005],
  [119.68620375257045, 41.40614265932511],
  [119.68570406575914, 41.405714356344035],
  [119.68556129809888, 41.40485775038196],
  [119.68573975767426, 41.40435806357074],
  [119.68623944448548, 41.404108220165135],
  [119.68588252533462, 41.40400114441989],
  [119.68503484235126, 41.40391786328474],
  [119.68474930703053, 41.40344197108354],
  [119.68484448547078, 41.402966078882336],
  [119.68493966391101, 41.40239500824099],
  [119.68465412859038, 41.40229982980075],
  [119.68413064716906, 41.4031088465427],
  [119.68365475496786, 41.40329920342318],
  [119.68322645198687, 41.40301366810246],
  [119.68341680886735, 41.402537775901344],
  [119.68394029028858, 41.40206188370014],
  [119.68341680886735, 41.40144322383867],
  [119.68360716574774, 41.40087215319723],
  [119.68360716574774, 41.399920368794916],
  [119.68341680886735, 41.39858787063164],
  [119.6839878795087, 41.398397513751156],
  [119.6842258256093, 41.39792162155005],
  [119.68460653937026, 41.39768367544944],
  [119.68493966391101, 41.39663671260689],
  [119.68479689625066, 41.39630358806605],
  [119.68527278845185, 41.39630358806605],
  [119.68555832377258, 41.396113231185566],
  [119.68617698363406, 41.39549457132409],
  [119.68603421597369, 41.39520903600337],
  [119.68503484235126, 41.395161446783256],
  [119.68498725313114, 41.39463796536193],
  [119.68417823638919, 41.39473314380217],
  [119.68394029028858, 41.39440001926142],
  [119.68365475496786, 41.39378135939986],
  [119.68365475496786, 41.393353056418775],
  [119.68451136093002, 41.393543413299255],
  [119.68432100404954, 41.3927343965573],
  [119.68394029028858, 41.39235368279634],
  [119.68303609510639, 41.39183020137511],
  [119.68113252630167, 41.39149707683427],
  [119.68013315267923, 41.39178261215499],
  [119.67951449281776, 41.39183020137511],
  [119.67894342217632, 41.39173502293487],
  [119.67856270841544, 41.39140189839403],
  [119.677991637774, 41.391259130733665],
  [119.67794404855388, 41.39092600619283],
  [119.67784887011365, 41.39025975711123],
  [119.67738125485637, 41.389302443219606],
  [119.67744400000004, 41.38918899999999],
  [119.67750899999999, 41.388923000000005],
  [119.67748099999999, 41.38864300000002],
  [119.677392, 41.38818299999998],
  [119.67737799999999, 41.38792099999997],
  [119.67739399999998, 41.387886],
  [119.677508, 41.38779400000003],
  [119.67767600000005, 41.387755999999996],
  [119.67799, 41.38775400000001],
  [119.67836700000002, 41.387799],
  [119.67852299999996, 41.387784],
  [119.67888699999996, 41.38761199999998],
  [119.67931400000003, 41.38739299999998],
  [119.67949899999998, 41.38731500000001],
  [119.68003300000002, 41.38721200000001],
  [119.68063100000002, 41.38705599999999],
  [119.68097799999995, 41.386975000000035],
  [119.681526, 41.386909999999986],
  [119.68199599999998, 41.38687899999999],
  [119.68231100000003, 41.38684500000002],
  [119.68257999999999, 41.386756000000034],
  [119.68291100000002, 41.386717999999995],
  [119.68318399999997, 41.38671100000004],
  [119.68351599999998, 41.38658999999998],
  [119.68365099999997, 41.386527],
  [119.68377600000001, 41.38643300000004],
  [119.68380400000001, 41.38637800000002],
  [119.68379899999995, 41.38633100000004],
  [119.68372299999997, 41.386229000000036],
  [119.68340700000003, 41.38606099999999],
  [119.68318299999997, 41.385782999999996],
  [119.68273500000002, 41.385331999999984],
  [119.68240100000001, 41.384964],
  [119.68221000000003, 41.384671999999995],
  [119.68219300000004, 41.38459500000002],
  [119.68219400000002, 41.38449799999999],
  [119.68222200000004, 41.38436200000002],
  [119.68228799999999, 41.38410000000001],
  [119.68230599999997, 41.38378399999998],
  [119.68232400000004, 41.38350000000001],
  [119.68238400000003, 41.38324499999997],
  [119.68244100000004, 41.383005000000004],
  [119.68254500000003, 41.38265000000003],
  [119.68261499999996, 41.382509],
  [119.682753, 41.38230899999997],
  [119.68281699999997, 41.38208000000003],
  [119.68283600000004, 41.381973999999964],
  [119.68283600000004, 41.38188199999999],
  [119.68270600000002, 41.38158100000004],
  [119.682682, 41.38147699999996],
  [119.682667, 41.38131199999999],
  [119.68270200000005, 41.381125999999966],
  [119.68278899999997, 41.38077099999999],
  [119.682885, 41.38058399999997],
  [119.68302900000002, 41.380445000000016],
  [119.68331600000005, 41.380223000000036],
  [119.68339300000002, 41.38010199999997],
  [119.68342000000004, 41.38000300000004],
  [119.683397, 41.379885000000044],
  [119.68332000000002, 41.379759000000014],
  [119.68293800000004, 41.37944099999999],
  [119.68272200000001, 41.37920400000001],
  [119.68243899999996, 41.37869000000003],
  [119.68223700000003, 41.37831999999997],
  [119.68218699999998, 41.378139999999995],
  [119.68214900000004, 41.37779600000004],
  [119.68220700000003, 41.377427999999966],
  [119.68233599999996, 41.37702500000002],
  [119.68250899999998, 41.37674800000001],
  [119.68262699999997, 41.37651999999998],
  [119.68280900000002, 41.376209],
  [119.68284499999999, 41.37613000000004],
  [119.68285000000004, 41.37605700000004],
  [119.68283399999996, 41.37582800000001],
  [119.68288999999997, 41.375641999999985],
  [119.68298100000004, 41.375407999999986],
  [119.683017, 41.375216],
  [119.68296599999995, 41.374631],
  [119.68278599999998, 41.37442300000002],
  [119.682741, 41.374368000000004],
  [119.68218200000001, 41.373642999999966],
  [119.68208100000001, 41.37352800000004],
  [119.68191299999997, 41.37345900000002],
  [119.68161200000002, 41.373367000000044],
  [119.68121700000002, 41.373241000000014],
  [119.68088699999998, 41.37305899999996],
  [119.68065599999996, 41.37292599999997],
  [119.680518, 41.372822999999975],
  [119.68030900000002, 41.37276399999998],
  [119.67999799999997, 41.37268200000003],
  [119.679715, 41.37254599999997],
  [119.67932399999998, 41.37232899999996],
  [119.67910499999998, 41.372175000000006],
  [119.67903600000004, 41.37208400000003],
  [119.67882799999997, 41.37186400000003],
  [119.67860300000001, 41.37167700000001],
  [119.67811099999997, 41.37143599999997],
  [119.67802499999996, 41.371403999999984],
  [119.67789700000003, 41.371363999999964],
  [119.67727999999997, 41.371130999999956],
  [119.67707, 41.37103300000002],
  [119.67697800000002, 41.37096699999998],
  [119.67696200000003, 41.37095499999996],
  [119.67686400000001, 41.370854000000044],
  [119.67669, 41.370570999999984],
  [119.676586, 41.37031199999996],
  [119.67641899999995, 41.369955],
  [119.67610999999997, 41.369536999999966],
  [119.67579300000003, 41.36925499999999],
  [119.67547899999998, 41.36903999999996],
  [119.67525100000003, 41.36883099999999],
  [119.675141, 41.36856799999999],
  [119.67513800000002, 41.36845700000004],
  [119.67516200000004, 41.36836000000001],
  [119.67533299999998, 41.368182000000026],
  [119.67548600000003, 41.368084],
  [119.675535, 41.36802899999998],
  [119.67559699999998, 41.36792000000002],
  [119.67562399999998, 41.36779299999999],
  [119.675578, 41.36752600000002],
  [119.67552499999996, 41.367383999999994],
  [119.67549199999999, 41.367210999999976],
  [119.67547000000003, 41.36697599999999],
  [119.67548900000001, 41.36659999999996],
  [119.67549399999999, 41.366576000000016],
  [119.67550200000002, 41.36655299999998],
  [119.67552599999996, 41.36643399999999],
  [119.67552, 41.36638500000002],
  [119.675462, 41.36633399999998],
  [119.675242, 41.366204999999965],
  [119.675185, 41.36615400000001],
  [119.67510500000003, 41.36607900000002],
  [119.67500400000003, 41.365916000000034],
  [119.67485700000003, 41.36558799999998],
  [119.67478300000003, 41.36549300000003],
  [119.67470599999997, 41.365411999999985],
  [119.674527, 41.36531700000003],
  [119.67439200000001, 41.36522499999997],
  [119.67412299999997, 41.365074],
  [119.673795, 41.364971000000004],
  [119.67292600000005, 41.364772999999964],
  [119.672786, 41.36474299999997],
  [119.672277, 41.364617000000024],
  [119.67195999999997, 41.364496000000045],
  [119.671631, 41.36424399999998],
  [119.67154300000001, 41.36409300000001],
  [119.67152500000003, 41.36373800000003],
  [119.67142400000003, 41.36353200000004],
  [119.67125000000001, 41.36324899999999],
  [119.67123300000003, 41.363159],
  [119.67137399999997, 41.36289100000003],
  [119.67149299999996, 41.36265600000004],
  [119.67149600000003, 41.362645000000015],
  [119.67158700000002, 41.36245600000001],
  [119.67165299999996, 41.36236800000001],
  [119.67172900000004, 41.362211999999985],
  [119.67185499999998, 41.361919999999984],
  [119.67196400000003, 41.36171899999996],
  [119.672172, 41.361552999999994],
  [119.67237899999999, 41.36142399999998],
  [119.672467, 41.36133499999998],
  [119.67252799999997, 41.361215],
  [119.67252599999999, 41.36114399999999],
  [119.672465, 41.361059999999966],
  [119.67221200000003, 41.36080899999998],
  [119.67214599999998, 41.36068300000004],
  [119.67203800000003, 41.36035999999996],
  [119.67198899999997, 41.36006900000004],
  [119.67192000000003, 41.35994200000001],
  [119.67177400000003, 41.359791000000044],
  [119.67158100000005, 41.35944999999998],
  [119.67146199999998, 41.359234999999956],
  [119.67129100000004, 41.35914199999999],
  [119.67094799999998, 41.35898099999999],
  [119.67079500000003, 41.35882600000004],
  [119.67069300000003, 41.358625000000025],
  [119.670682, 41.358572999999986],
  [119.67062799999998, 41.35854599999997],
  [119.670436, 41.35852500000001],
  [119.66994199999998, 41.358439999999995],
  [119.66942100000003, 41.35837600000003],
  [119.66925299999998, 41.35837300000004],
  [119.66916899999997, 41.35839999999997],
  [119.66908500000002, 41.35842200000001],
  [119.668827, 41.358482],
  [119.66861700000003, 41.35848399999999],
  [119.66857100000004, 41.35848399999999],
  [119.66845399999995, 41.358439999999995],
  [119.66825000000004, 41.35841099999999],
  [119.66799799999997, 41.35834800000002],
  [119.66790300000002, 41.35832599999998],
  [119.66770499999998, 41.358327999999965],
  [119.667351, 41.358353999999984],
  [119.66729599999998, 41.35836200000002],
  [119.66700199999998, 41.358407000000014],
  [119.66678599999997, 41.358392000000016],
  [119.66669399999999, 41.35834700000002],
  [119.666604, 41.35826999999996],
  [119.66644699999999, 41.358000999999994],
  [119.66623199999997, 41.35777700000003],
  [119.66605800000004, 41.35760100000003],
  [119.665979, 41.357494999999965],
  [119.66580899999995, 41.357279000000034],
  [119.665699, 41.35718499999998],
  [119.66558499999998, 41.35709699999998],
  [119.66546100000002, 41.35704700000002],
  [119.665289, 41.356999000000044],
  [119.66483200000003, 41.35677799999997],
  [119.66476899999996, 41.35673299999998],
  [119.66475399999997, 41.35672200000004],
  [119.66465099999998, 41.356692000000045],
  [119.66444399999999, 41.35665699999999],
  [119.66413899999998, 41.356568],
  [119.66393599999996, 41.35646400000001],
  [119.6638, 41.35633100000002],
  [119.66372400000002, 41.35622700000003],
  [119.66371699999996, 41.35606800000002],
  [119.663699, 41.35593099999996],
  [119.66363999999999, 41.355819000000025],
  [119.66361800000004, 41.355795999999984],
  [119.66342299999998, 41.35567999999998],
  [119.663244, 41.35556499999997],
  [119.66314599999997, 41.35543700000004],
  [119.66311899999997, 41.355413000000006],
  [119.66306099999996, 41.35532500000001],
  [119.66301799999995, 41.35526999999998],
  [119.66291900000002, 41.35519799999998],
  [119.66240900000001, 41.355062000000004],
  [119.662104, 41.35497999999997],
  [119.661826, 41.354807000000044],
  [119.661519, 41.35462200000001],
  [119.66123399999996, 41.35450600000001],
  [119.66083799999997, 41.35440200000002],
  [119.66033200000003, 41.35429399999997],
  [119.66014300000002, 41.35420800000004],
  [119.66005400000003, 41.354103999999964],
  [119.65999500000002, 41.35389999999996],
  [119.659926, 41.353820000000006],
  [119.65976200000003, 41.35370499999999],
  [119.65948400000002, 41.35352400000003],
  [119.659225, 41.35326400000001],
  [119.65912100000001, 41.353062999999985],
  [119.65893000000001, 41.352754],
  [119.658774, 41.35228300000002],
  [119.65866400000003, 41.35201800000003],
  [119.65845900000004, 41.35176],
  [119.65829700000005, 41.35163299999997],
  [119.65782499999999, 41.35132599999997],
  [119.657618, 41.351201000000025],
  [119.65753800000005, 41.35118099999997],
  [119.65745100000004, 41.35117400000001],
  [119.65707200000003, 41.35121800000001],
  [119.65652599999997, 41.35124600000002],
  [119.65625600000001, 41.351221999999986],
  [119.655775, 41.351075999999985],
  [119.65512700000004, 41.35091599999998],
  [119.65477600000004, 41.35081099999999],
  [119.65456200000001, 41.35073300000003],
  [119.65441500000001, 41.35062499999997],
  [119.654359, 41.350514000000025],
  [119.65436900000003, 41.35026600000002],
  [119.65436599999995, 41.35017900000002],
  [119.65436700000004, 41.35015899999996],
  [119.65434300000001, 41.35010700000001],
  [119.65420900000002, 41.35000500000001],
  [119.653919, 41.34971899999997],
  [119.65382399999996, 41.349612],
  [119.65371699999999, 41.34942900000004],
  [119.65361899999996, 41.349181000000044],
  [119.65343400000002, 41.34897399999997],
  [119.65335399999998, 41.348867],
  [119.65328900000002, 41.348648],
  [119.65322700000004, 41.34852199999996],
  [119.65319399999997, 41.34848300000002],
  [119.65304999999996, 41.34848500000001],
  [119.65285200000001, 41.34848500000001],
  [119.65247699999998, 41.34848300000002],
  [119.65174799999997, 41.34837399999997],
  [119.65138600000003, 41.348348000000044],
  [119.65097199999998, 41.348362000000044],
  [119.65076899999997, 41.34831900000004],
  [119.65060100000001, 41.34821499999996],
  [119.65048200000003, 41.34807200000003],
  [119.65044499999998, 41.347810000000024],
  [119.65040900000002, 41.34774699999996],
  [119.65020600000001, 41.34756000000004],
  [119.65006299999999, 41.34738600000003],
  [119.65001300000003, 41.34729399999996],
  [119.65000100000002, 41.34717799999996],
  [119.64995400000004, 41.34708100000002],
  [119.64984000000001, 41.34699500000001],
  [119.649769, 41.34693400000002],
  [119.64967400000005, 41.34664699999999],
  [119.64964800000003, 41.34644199999999],
  [119.64961900000003, 41.34637900000002],
  [119.64949799999997, 41.346297999999976],
]
var anhuiLine2 = [
  [119.64929800000003, 41.346188000000026],
  [119.64922199999995, 41.346164999999985],
  [119.64906000000005, 41.346091999999985],
  [119.64894099999998, 41.346061],
  [119.64840300000004, 41.34591300000001],
  [119.64825099999999, 41.345837000000024],
  [119.64808300000004, 41.34564099999997],
  [119.64793900000002, 41.34554700000001],
  [119.64785899999998, 41.34552099999999],
  [119.64775899999997, 41.345516000000025],
  [119.64766200000003, 41.34554600000002],
  [119.64736199999997, 41.345843999999985],
  [119.64724900000004, 41.345982000000035],
  [119.647183, 41.346102000000016],
  [119.64711499999997, 41.34620200000003],
  [119.64700400000002, 41.34633400000003],
  [119.64684300000002, 41.34644199999999],
  [119.64664799999997, 41.346587],
  [119.64664699999997, 41.346587],
  [119.64621999999999, 41.34678099999997],
  [119.64591799999997, 41.34692599999998],
  [119.64574199999997, 41.34702200000002],
  [119.64564800000001, 41.34702899999998],
  [119.645238, 41.34687700000001],
  [119.64454499999995, 41.346654000000036],
  [119.64396500000002, 41.34645200000002],
  [119.643777, 41.346411999999994],
  [119.64366599999997, 41.34641399999998],
  [119.64347900000004, 41.34650199999998],
  [119.642988, 41.346736999999976],
  [119.64281499999998, 41.346755000000044],
  [119.64266999999998, 41.346742000000035],
  [119.64251400000005, 41.34666599999996],
  [119.64242499999996, 41.34660599999997],
  [119.64231799999999, 41.346582000000026],
  [119.64208999999995, 41.346617999999985],
  [119.64166499999996, 41.346608000000046],
  [119.64152000000004, 41.34658999999998],
  [119.64130600000001, 41.34651599999999],
  [119.64107799999998, 41.346376000000035],
  [119.64093299999998, 41.346222],
  [119.64079699999999, 41.345976999999976],
  [119.64056599999998, 41.34565599999997],
  [119.64039900000002, 41.345444000000015],
  [119.64029099999996, 41.34522100000004],
  [119.64028999999996, 41.34521699999998],
  [119.64013099999995, 41.34487000000004],
  [119.63983599999997, 41.34449200000003],
  [119.63976399999997, 41.34443999999999],
  [119.639685, 41.344424],
  [119.63929399999998, 41.34450099999997],
  [119.639, 41.34458100000002],
  [119.63889999999998, 41.34457],
  [119.63860999999997, 41.344509000000016],
  [119.63814399999995, 41.34449200000003],
  [119.63797200000002, 41.34451699999997],
  [119.63782500000003, 41.34450500000004],
  [119.63770499999995, 41.344463000000026],
  [119.63748, 41.34426599999998],
  [119.63736699999997, 41.34415400000004],
  [119.63733599999998, 41.34407399999999],
  [119.63735800000002, 41.34383200000004],
  [119.637347, 41.34366999999996],
  [119.63725700000002, 41.343420999999964],
  [119.637156, 41.343190000000035],
  [119.63716199999998, 41.343100999999955],
  [119.63719299999997, 41.34307500000003],
  [119.63729499999997, 41.34297400000002],
  [119.63738299999996, 41.342942000000036],
  [119.63762000000003, 41.34288600000002],
  [119.63778799999999, 41.342817],
  [119.63783099999999, 41.342743999999996],
  [119.637844, 41.342672999999984],
  [119.63776400000005, 41.34231400000003],
  [119.63764399999998, 41.341984],
  [119.63761499999997, 41.341881],
  [119.63761499999997, 41.34178499999996],
  [119.63763700000001, 41.34171400000004],
  [119.63764299999998, 41.34170699999999],
  [119.63773299999997, 41.34158700000001],
  [119.63782500000003, 41.341516],
  [119.637889, 41.341438000000025],
  [119.63791300000003, 41.34133400000004],
  [119.63791400000002, 41.34113699999999],
  [119.63786900000002, 41.340618000000035],
  [119.63783, 41.340551],
  [119.63771499999999, 41.340485000000044],
  [119.63724200000001, 41.34034999999998],
  [119.63715200000003, 41.34033900000004],
  [119.63679999999995, 41.34029900000002],
  [119.63670000000003, 41.340258999999996],
  [119.63652200000004, 41.34011499999998],
  [119.63621299999997, 41.33973100000001],
  [119.636135, 41.339524000000026],
  [119.63604500000001, 41.33922399999998],
  [119.63591100000002, 41.33885699999999],
  [119.63595200000005, 41.33860100000004],
  [119.63611299999995, 41.33836700000004],
  [119.63616899999997, 41.33830800000004],
  [119.63626899999998, 41.338278000000045],
  [119.63635699999998, 41.338251000000035],
  [119.63636099999995, 41.33815799999998],
  [119.63625799999996, 41.33806799999999],
  [119.635988, 41.33795900000003],
  [119.63559799999997, 41.337653000000024],
  [119.63544300000002, 41.337574999999966],
  [119.63533199999999, 41.337551000000026],
  [119.63519200000005, 41.33757099999999],
  [119.63500099999996, 41.337633999999966],
  [119.63484099999995, 41.33765400000002],
  [119.63466199999998, 41.337647999999966],
  [119.63427200000004, 41.33756800000001],
  [119.63397399999998, 41.33744700000003],
  [119.63377199999996, 41.337321],
  [119.63367300000004, 41.33714100000003],
  [119.63360200000002, 41.337058999999996],
  [119.63337200000001, 41.33694499999998],
  [119.63313400000003, 41.33677900000001],
  [119.632816, 41.33633600000004],
  [119.63276199999999, 41.336254999999994],
  [119.63273299999997, 41.336072000000044],
  [119.63272600000002, 41.33594300000003],
  [119.63272200000004, 41.33590699999998],
  [119.63269700000002, 41.335761999999974],
  [119.632626, 41.335699],
  [119.632247, 41.33558499999998],
  [119.63208000000004, 41.33549699999998],
  [119.63194499999997, 41.335392],
  [119.63168400000005, 41.33513900000003],
  [119.63121499999997, 41.334939999999996],
  [119.63075400000001, 41.334659000000016],
  [119.63048399999997, 41.33449900000001],
  [119.63043699999999, 41.33447000000001],
  [119.63024200000002, 41.33428799999996],
  [119.63016800000003, 41.33416599999999],
  [119.63010700000004, 41.334033],
  [119.630056, 41.33388200000003],
  [119.63003400000004, 41.333755999999994],
  [119.63000600000004, 41.333653],
  [119.62997900000002, 41.33351900000002],
  [119.62999000000003, 41.333335999999974],
  [119.62999100000003, 41.33333499999998],
  [119.630173, 41.333039],
  [119.63024999999998, 41.33265400000003],
  [119.63038900000001, 41.33248599999999],
  [119.63057299999996, 41.33196600000004],
  [119.63064499999997, 41.331730999999955],
  [119.63058499999998, 41.33103400000002],
  [119.63055599999997, 41.33056500000003],
  [119.63022400000004, 41.33002300000003],
  [119.62961999999997, 41.329684999999955],
  [119.62952500000003, 41.32957699999999],
  [119.62941499999998, 41.32926400000003],
  [119.62932000000002, 41.328976000000004],
  [119.62919599999998, 41.328410999999974],
  [119.62918299999997, 41.32799000000004],
  [119.628827, 41.327508000000044],
  [119.62859599999999, 41.32728299999999],
  [119.62877399999996, 41.327270999999975],
  [119.62889600000003, 41.327258999999955],
  [119.62907100000002, 41.32721299999997],
  [119.62949800000001, 41.327164],
  [119.62999400000001, 41.32709499999998],
  [119.63019300000005, 41.32706499999998],
  [119.63065100000001, 41.326954000000036],
  [119.63083399999998, 41.326882000000026],
  [119.630933, 41.326821000000045],
  [119.63103200000002, 41.32674399999998],
  [119.63109299999999, 41.326653],
  [119.63119200000001, 41.32649599999998],
  [119.631268, 41.32632099999997],
  [119.63135200000002, 41.325878],
  [119.63138300000001, 41.32569900000002],
  [119.63142100000005, 41.32562300000004],
  [119.631429, 41.32556500000004],
  [119.63148200000003, 41.325397999999986],
  [119.63155099999997, 41.32528299999998],
  [119.63166499999998, 41.325142000000035],
  [119.63180999999999, 41.32502400000004],
  [119.63191699999996, 41.32492500000002],
  [119.63204699999997, 41.324832999999956],
  [119.63232900000004, 41.32457400000002],
  [119.632482, 41.32429099999997],
  [119.63250400000004, 41.324139],
  [119.63250400000004, 41.32408199999999],
  [119.63250400000004, 41.323929000000035],
  [119.63242799999996, 41.32357399999997],
  [119.632291, 41.323218999999995],
  [119.63225299999996, 41.32315099999997],
  [119.63215300000005, 41.322998000000005],
  [119.63212300000005, 41.32292200000003],
  [119.63212300000005, 41.322868],
  [119.632161, 41.322726999999965],
  [119.63238199999998, 41.32238799999998],
  [119.63251199999999, 41.32218599999997],
  [119.63276399999997, 41.321655],
  [119.63287100000002, 41.32134600000001],
  [119.63291700000002, 41.32131200000004],
  [119.632962, 41.32122099999997],
  [119.63315300000001, 41.32068700000002],
  [119.63325999999998, 41.32029699999999],
  [119.633313, 41.32006499999998],
  [119.633313, 41.319873999999984],
  [119.63327499999997, 41.31963400000002],
  [119.63324399999998, 41.319477],
  [119.63322100000003, 41.31909200000003],
  [119.63325200000003, 41.31882099999999],
  [119.633283, 41.31869099999998],
  [119.63333600000004, 41.318611000000026],
  [119.63341200000002, 41.31855000000004],
  [119.63352700000004, 41.318488999999964],
  [119.63358800000002, 41.31846999999999],
  [119.63384700000005, 41.31842400000001],
  [119.63392300000002, 41.31839799999999],
  [119.63402300000004, 41.318333000000024],
  [119.63410699999997, 41.318257000000045],
  [119.63424400000004, 41.31819900000004],
  [119.63493100000004, 41.318089],
  [119.63514399999998, 41.31799299999996],
  [119.63567800000003, 41.317658000000044],
  [119.63588400000002, 41.31757400000002],
  [119.63605199999996, 41.317486000000024],
  [119.63637299999998, 41.31728000000003],
  [119.63649500000002, 41.31720700000003],
  [119.63662399999996, 41.317139],
  [119.63685299999999, 41.31698600000004],
  [119.63707499999997, 41.31684100000003],
  [119.63716600000004, 41.316806999999976],
  [119.63722300000005, 41.316806999999976],
  [119.63724200000001, 41.316806999999976],
  [119.637303, 41.316818],
  [119.63748599999995, 41.31697500000002],
  [119.63783, 41.31732200000004],
  [119.63834900000005, 41.31779899999999],
  [119.63847799999996, 41.31793600000004],
  [119.638677, 41.31818800000002],
  [119.63878299999998, 41.318264],
  [119.63945499999998, 41.318527],
  [119.63985900000002, 41.318805999999995],
  [119.63995099999998, 41.31890100000004],
  [119.64005699999997, 41.31901199999999],
  [119.64010300000004, 41.31909200000003],
  [119.64016400000003, 41.31922200000004],
  [119.64026400000004, 41.31953400000001],
  [119.64033199999999, 41.31969799999999],
  [119.64053799999998, 41.32020600000001],
  [119.64069899999997, 41.32050299999998],
  [119.64088899999997, 41.32070899999997],
  [119.640988, 41.32092300000001],
  [119.64104899999998, 41.32102999999998],
  [119.641248, 41.32133499999999],
  [119.64142300000002, 41.321552000000004],
  [119.64151499999998, 41.321652000000014],
  [119.641614, 41.32170499999996],
  [119.64172900000001, 41.32175500000001],
  [119.64189599999997, 41.32178899999998],
  [119.64208699999998, 41.3218],
  [119.64235400000004, 41.32177699999997],
  [119.64275100000002, 41.321757999999996],
  [119.643178, 41.32176600000003],
  [119.64388800000005, 41.32180399999998],
  [119.64404, 41.32181899999998],
  [119.64410900000003, 41.32183499999997],
  [119.64419299999996, 41.32186099999999],
  [119.64449000000002, 41.322112999999966],
  [119.64471899999997, 41.322299999999984],
  [119.64505499999997, 41.32254799999999],
  [119.64538300000001, 41.322799999999965],
  [119.64555800000002, 41.32290700000003],
  [119.64565800000003, 41.322941],
  [119.645765, 41.32295200000002],
  [119.645925, 41.32292200000003],
  [119.64599300000003, 41.32288399999999],
  [119.64609299999996, 41.32279599999999],
  [119.64616099999999, 41.32270100000004],
  [119.64626000000001, 41.32262800000004],
  [119.64637500000002, 41.322575],
  [119.64661100000001, 41.322487],
  [119.646863, 41.322342],
  [119.64709899999998, 41.32217400000004],
  [119.64725999999997, 41.322078999999995],
  [119.64744999999998, 41.32203300000001],
  [119.64757200000004, 41.32202199999999],
  [119.647695, 41.32202199999999],
  [119.64778599999998, 41.32203300000001],
  [119.64787800000003, 41.322064],
  [119.64822099999999, 41.32228499999999],
  [119.64845799999998, 41.322415],
  [119.648778, 41.322617000000015],
  [119.64883899999997, 41.32265499999996],
  [119.64890799999999, 41.322670000000045],
  [119.64922800000001, 41.322665999999984],
  [119.64945700000004, 41.32267400000002],
  [119.64963999999999, 41.32269699999997],
  [119.65015900000003, 41.32286100000004],
  [119.65032699999999, 41.32292899999998],
  [119.650487, 41.322941],
  [119.65124200000002, 41.322926],
  [119.65179199999997, 41.32291000000001],
  [119.65222699999998, 41.32288000000001],
  [119.65262299999998, 41.322876000000036],
  [119.65278299999999, 41.322898999999985],
  [119.652883, 41.322926],
  [119.65294399999998, 41.322964000000034],
  [119.65301200000002, 41.323029],
  [119.65310399999998, 41.323192999999975],
  [119.65316499999997, 41.323315000000036],
  [119.65336300000001, 41.323555],
  [119.65350900000001, 41.32367699999997],
  [119.65361499999999, 41.32373399999997],
  [119.65384400000002, 41.323830000000015],
  [119.65422600000001, 41.324012999999965],
  [119.65448500000004, 41.32416200000004],
  [119.65456899999997, 41.324256999999996],
  [119.654637, 41.32436399999997],
  [119.65480500000004, 41.32460799999999],
  [119.65481100000001, 41.32467800000001],
  [119.65481299999999, 41.324703000000035],
  [119.65474399999997, 41.32523000000003],
  [119.65475999999995, 41.325519999999955],
  [119.654783, 41.325790999999995],
  [119.65482100000003, 41.325966],
  [119.65487399999998, 41.32609600000001],
  [119.65491999999996, 41.32615999999997],
  [119.65498100000003, 41.326214],
  [119.65504999999997, 41.32623700000004],
  [119.65522499999997, 41.32626700000004],
  [119.65550700000004, 41.326289999999986],
  [119.65587300000003, 41.32632500000004],
  [119.65608000000002, 41.326355000000035],
  [119.65627799999997, 41.32645399999996],
  [119.65651500000004, 41.326583999999976],
  [119.656682, 41.326637000000005],
  [119.65697200000001, 41.32669799999999],
  [119.65716300000001, 41.326755999999996],
  [119.65763599999998, 41.326903999999985],
  [119.65810099999999, 41.32708700000003],
  [119.65823899999995, 41.32712199999999],
  [119.65849799999998, 41.32716000000003],
  [119.65875700000001, 41.32716799999998],
  [119.65895600000003, 41.327179],
  [119.65922999999997, 41.32714500000003],
  [119.65939800000001, 41.327136999999986],
  [119.65954300000003, 41.32715599999996],
  [119.65961199999995, 41.32718700000004],
  [119.65980300000004, 41.32739999999999],
  [119.65996300000005, 41.327617999999994],
  [119.66015400000003, 41.327881],
  [119.66023000000003, 41.327983999999994],
  [119.66034400000004, 41.32810199999999],
  [119.660627, 41.328362000000006],
  [119.66087100000003, 41.32860199999997],
  [119.66099999999996, 41.32879299999996],
  [119.66122200000004, 41.329021],
  [119.66130599999997, 41.32909000000002],
  [119.66164099999997, 41.329303999999965],
  [119.66179400000001, 41.329495000000044],
  [119.66201500000001, 41.329765],
  [119.66208400000004, 41.329849000000024],
  [119.66238100000001, 41.33021200000004],
  [119.66280100000003, 41.33047500000004],
  [119.66293100000004, 41.33050500000004],
  [119.66296100000004, 41.330555],
  [119.66314399999999, 41.330669000000015],
  [119.66337300000002, 41.33087899999998],
  [119.66367099999998, 41.33122300000002],
  [119.66390699999997, 41.33145900000001],
  [119.664136, 41.331661000000025],
  [119.66435699999998, 41.33180199999997],
  [119.664472, 41.33185200000002],
  [119.66458600000001, 41.331879000000036],
  [119.66515099999995, 41.33185999999997],
  [119.665364, 41.33186700000002],
  [119.66544900000001, 41.33188300000001],
  [119.665655, 41.33194699999998],
  [119.66597500000002, 41.33214200000003],
  [119.66613500000001, 41.33223699999999],
  [119.66671499999995, 41.33248899999997],
  [119.66682200000001, 41.332542000000004],
  [119.66693600000004, 41.33259600000003],
  [119.66699700000002, 41.332642000000014],
  [119.667074, 41.332741000000034],
  [119.66713499999997, 41.33297700000002],
  [119.667234, 41.33318699999999],
  [119.66729499999998, 41.333241000000015],
  [119.66746300000003, 41.333340000000035],
  [119.66749999999998, 41.33337100000003],
  [119.667615, 41.333468999999965],
  [119.66769900000001, 41.33355],
  [119.66778300000004, 41.33367199999997],
]
var anhuiLine3 = [
  [119.66779399999997, 41.33368499999998],
  [119.66801199999998, 41.333954000000034],
  [119.668126, 41.33404899999999],
  [119.66837100000002, 41.33417099999996],
  [119.668592, 41.33429699999999],
  [119.66869100000002, 41.33433500000003],
  [119.668843, 41.334347000000044],
  [119.66902700000003, 41.334347000000044],
  [119.66922499999998, 41.33432400000001],
  [119.66946199999995, 41.33427400000004],
  [119.66970599999999, 41.33421700000004],
  [119.66981300000005, 41.33421299999997],
  [119.66992699999997, 41.334228999999965],
  [119.67011000000002, 41.334281999999995],
  [119.670537, 41.33446500000004],
  [119.67088799999999, 41.33462099999998],
  [119.671224, 41.33474400000003],
  [119.671545, 41.33480100000004],
  [119.67172000000001, 41.33485000000001],
  [119.67201799999997, 41.334941999999984],
  [119.67233000000003, 41.33507199999999],
  [119.672933, 41.33533100000002],
  [119.67304800000001, 41.33540300000002],
  [119.67320800000002, 41.335545000000046],
  [119.673269, 41.33556400000002],
  [119.67335300000002, 41.335533000000034],
  [119.67361200000005, 41.33545299999999],
  [119.67375699999997, 41.335441999999965],
  [119.67391699999997, 41.33545299999999],
  [119.67412299999997, 41.33547600000002],
  [119.674276, 41.33552200000001],
  [119.67444399999997, 41.33558299999999],
  [119.67492399999998, 41.335739000000025],
  [119.67527499999998, 41.335838000000045],
  [119.67561900000003, 41.33592600000004],
  [119.67586300000005, 41.33597200000003],
  [119.676015, 41.336033000000015],
  [119.676191, 41.336132000000035],
  [119.67632100000002, 41.33625000000003],
  [119.67642000000004, 41.336357],
  [119.67647299999999, 41.33643],
  [119.67653399999996, 41.336619999999996],
  [119.67667100000003, 41.336986999999986],
  [119.676748, 41.337134999999975],
  [119.67679399999999, 41.33716599999997],
  [119.67685499999997, 41.33718500000003],
  [119.67699200000003, 41.33719599999996],
  [119.67709100000005, 41.33721500000002],
  [119.67716700000003, 41.33724999999999],
  [119.67721300000001, 41.33730300000002],
  [119.67731200000003, 41.33743300000003],
  [119.67735099999997, 41.33758899999997],
  [119.67739599999997, 41.337634999999956],
  [119.67767900000003, 41.33785600000004],
  [119.67790000000001, 41.33803600000001],
  [119.67796800000005, 41.33808499999998],
  [119.67802199999998, 41.33821100000001],
  [119.67806799999997, 41.33853499999999],
  [119.67815900000004, 41.33869599999999],
  [119.67820500000002, 41.338738000000006],
  [119.67856299999998, 41.339058000000016],
  [119.67886899999999, 41.33930200000004],
  [119.67894499999997, 41.339352000000005],
  [119.67900600000004, 41.33935899999996],
  [119.67909800000001, 41.33935499999998],
  [119.67921999999999, 41.33932499999999],
  [119.67947099999996, 41.33926400000001],
  [119.67973800000003, 41.33917600000001],
  [119.679876, 41.33913799999997],
  [119.67999799999997, 41.339146000000014],
  [119.680212, 41.33919900000004],
  [119.68032600000001, 41.33923699999999],
  [119.680532, 41.33933600000001],
  [119.68090599999995, 41.33957699999997],
  [119.68110399999999, 41.339766999999966],
  [119.68127999999999, 41.339905000000016],
  [119.681379, 41.33994299999996],
  [119.68152400000001, 41.339996],
  [119.68166900000003, 41.340026999999985],
  [119.68192, 41.34003500000003],
  [119.68261499999996, 41.34004599999996],
  [119.68272200000001, 41.340069],
  [119.68310300000002, 41.34028999999999],
  [119.68320999999999, 41.340332],
  [119.68347699999995, 41.34042700000004],
  [119.683691, 41.340453999999966],
  [119.68392000000001, 41.340492000000005],
  [119.68411799999997, 41.34056100000003],
  [119.684347, 41.340625999999986],
  [119.68500300000001, 41.34080099999999],
  [119.68530800000002, 41.34090399999999],
  [119.68548300000002, 41.340980999999964],
  [119.68562800000004, 41.34106100000001],
  [119.68593400000003, 41.34125499999998],
  [119.68606299999996, 41.341311999999995],
  [119.68618500000002, 41.34136600000002],
  [119.68681100000003, 41.341533999999974],
  [119.68717700000003, 41.341632999999995],
  [119.687269, 41.341666999999966],
  [119.68732999999999, 41.341671000000034],
  [119.68740599999997, 41.34164799999999],
  [119.68756599999998, 41.341583000000035],
  [119.68778, 41.34148000000004],
  [119.68788699999998, 41.341442],
  [119.68801599999999, 41.341433999999964],
  [119.68807799999998, 41.341450000000044],
  [119.68824600000002, 41.34152800000001],
  [119.688512, 41.34159499999996],
  [119.68864999999997, 41.341605999999985],
  [119.68884600000001, 41.34160200000001],
  [119.68884800000001, 41.34160200000001],
  [119.68895499999998, 41.34159499999996],
  [119.68909200000003, 41.34161400000002],
  [119.68936700000005, 41.34168600000003],
  [119.689771, 41.341754999999964],
  [119.68997699999998, 41.341765999999986],
  [119.69034299999997, 41.34176300000001],
  [119.69057999999995, 41.341765999999986],
  [119.69074000000005, 41.34178499999996],
  [119.69118999999998, 41.341887999999955],
  [119.69138900000002, 41.34190800000001],
  [119.69150300000003, 41.34192999999997],
  [119.69170199999998, 41.34202999999998],
  [119.69185400000003, 41.34210199999999],
  [119.69193000000001, 41.34211699999999],
  [119.69222799999997, 41.342132999999976],
  [119.69252500000003, 41.342171000000015],
  [119.69277700000002, 41.34224300000002],
  [119.69293800000001, 41.34232700000004],
  [119.69311300000001, 41.34246100000003],
  [119.69323499999999, 41.342495],
  [119.693319, 41.34251399999997],
  [119.694006, 41.342559999999956],
  [119.69443299999999, 41.34257900000002],
  [119.69475299999999, 41.34261699999997],
  [119.695074, 41.34270499999997],
  [119.69528700000004, 41.34276600000004],
  [119.69555400000002, 41.34280000000001],
  [119.695951, 41.34284999999997],
  [119.69606600000002, 41.342857000000016],
  [119.69617199999999, 41.34288800000001],
  [119.69623299999998, 41.34293000000002],
  [119.69640100000002, 41.343123999999996],
  [119.696653, 41.34347499999999],
  [119.69675999999997, 41.34362399999998],
  [119.69683600000003, 41.343810999999995],
  [119.69686700000003, 41.34397900000004],
  [119.69692800000001, 41.344092999999965],
  [119.69734, 41.344485999999975],
  [119.697485, 41.34461999999996],
  [119.69766, 41.34471500000001],
  [119.69784300000005, 41.34482199999998],
  [119.69801100000001, 41.34497500000003],
  [119.69803700000003, 41.344998999999966],
  [119.69809500000002, 41.34505499999998],
  [119.69853000000005, 41.345543000000035],
  [119.69879700000003, 41.34588200000002],
  [119.69888100000004, 41.34602700000003],
  [119.69894200000003, 41.346279],
  [119.69897999999998, 41.346358999999964],
  [119.699079, 41.34642799999999],
  [119.69920099999996, 41.34648899999997],
  [119.69935400000001, 41.34657299999999],
  [119.69968199999997, 41.346821],
  [119.70000299999998, 41.347030999999966],
  [119.70004799999997, 41.34709500000002],
  [119.700102, 41.34722500000003],
  [119.70011699999999, 41.347350999999975],
  [119.70015500000002, 41.34762999999997],
  [119.70021600000001, 41.34771399999999],
  [119.70029199999999, 41.34778599999999],
  [119.70060499999995, 41.348048999999996],
  [119.70085700000003, 41.348269999999985],
  [119.70090999999996, 41.34834699999996],
  [119.70092599999997, 41.348408000000035],
  [119.700964, 41.34867500000001],
  [119.70103200000003, 41.34882000000002],
  [119.70117700000003, 41.34896500000002],
  [119.70126900000001, 41.349029999999985],
  [119.70165799999995, 41.349261999999996],
  [119.701795, 41.349383999999965],
  [119.70189400000002, 41.34949100000002],
  [119.70202400000004, 41.34961299999999],
  [119.70208500000003, 41.349730999999984],
  [119.70210799999997, 41.349869000000034],
  [119.70211600000002, 41.350108999999996],
  [119.70213899999996, 41.35016200000003],
  [119.70225299999998, 41.350296000000014],
  [119.70253500000004, 41.350674000000026],
  [119.702688, 41.350623999999975],
  [119.70277999999998, 41.350547999999996],
  [119.70294700000002, 41.350375999999976],
  [119.703039, 41.35031499999999],
  [119.70317599999997, 41.35027299999998],
  [119.70350400000001, 41.350196999999994],
  [119.70376400000004, 41.350174000000045],
  [119.70389299999997, 41.35012799999997],
  [119.70406100000001, 41.35003300000002],
  [119.70418299999997, 41.349936999999976],
  [119.70424400000005, 41.349842000000024],
  [119.70428299999999, 41.349730999999984],
  [119.70428299999999, 41.34962799999999],
  [119.70426000000003, 41.34946799999999],
  [119.704237, 41.34934600000002],
  [119.70424400000005, 41.349277999999984],
  [119.70428299999999, 41.349216000000006],
  [119.704428, 41.34910199999999],
  [119.70464100000004, 41.348784999999964],
  [119.70479399999999, 41.348633],
  [119.70486300000002, 41.34860200000001],
  [119.70493100000004, 41.34860599999998],
  [119.70531300000003, 41.34880400000002],
  [119.70538099999997, 41.34883100000004],
  [119.70549599999998, 41.348835000000015],
  [119.70558000000001, 41.348789000000025],
  [119.70568599999999, 41.34868199999997],
  [119.70586900000004, 41.34849900000001],
  [119.70599900000003, 41.34844599999998],
  [119.70621299999998, 41.34839999999999],
  [119.70639600000003, 41.34839200000004],
  [119.70673200000003, 41.348418999999964],
  [119.70703700000004, 41.34847599999998],
  [119.70727300000003, 41.34853699999996],
  [119.70744099999997, 41.348618],
  [119.70760099999998, 41.34873200000002],
  [119.707876, 41.34899899999999],
  [119.70793699999999, 41.34904499999998],
  [119.70802899999995, 41.349083000000014],
  [119.70818100000001, 41.34911300000001],
  [119.70831100000002, 41.34911000000003],
  [119.70884499999997, 41.34903299999996],
  [119.70912700000004, 41.34902899999999],
  [119.70930300000003, 41.34902600000001],
  [119.70938699999996, 41.349010000000014],
  [119.70982199999999, 41.348808],
  [119.70990500000002, 41.348789000000025],
  [119.71002000000003, 41.348808],
  [119.71024899999996, 41.34889999999997],
  [119.71038600000003, 41.34899899999999],
  [119.71048500000005, 41.349094000000036],
  [119.71057700000001, 41.349232],
  [119.710653, 41.34942599999997],
  [119.71071399999998, 41.349509999999995],
  [119.71082099999995, 41.34959799999999],
  [119.71098100000005, 41.34969700000001],
  [119.711325, 41.349853000000046],
  [119.711676, 41.35005599999997],
  [119.711866, 41.350169999999984],
  [119.71196500000002, 41.350196999999994],
  [119.71209500000003, 41.35022400000001],
  [119.71222500000003, 41.35027700000004],
  [119.71256799999999, 41.35049800000003],
  [119.712698, 41.35062800000004],
  [119.71273600000004, 41.350689000000024],
  [119.71281999999998, 41.350761000000034],
  [119.712919, 41.350795999999995],
  [119.71313999999998, 41.350834000000035],
  [119.71327800000003, 41.350868000000006],
  [119.71343, 41.35092200000003],
  [119.71354500000001, 41.350967000000026],
  [119.71365900000002, 41.351040000000026],
  [119.71376599999999, 41.35110499999998],
  [119.71407899999996, 41.35147099999998],
  [119.71419299999998, 41.35159700000001],
  [119.714468, 41.35182900000002],
  [119.71467399999999, 41.35206999999998],
  [119.71490999999997, 41.35238300000003],
  [119.71505499999998, 41.35258099999999],
  [119.71516999999999, 41.35270700000002],
  [119.71527699999996, 41.35279100000004],
  [119.71544400000002, 41.352874999999976],
  [119.71554400000002, 41.35295500000002],
  [119.71559699999997, 41.353031],
  [119.71577299999997, 41.353270999999964],
  [119.71583400000004, 41.35333200000004],
  [119.715986, 41.35342799999999],
  [119.71620000000004, 41.35352700000001],
  [119.71630700000001, 41.353558],
  [119.71639799999998, 41.35356900000002],
  [119.71664200000002, 41.35359199999997],
  [119.71678700000002, 41.35363399999998],
  [119.71711499999998, 41.353782999999964],
  [119.71757300000004, 41.35405300000001],
  [119.71771100000001, 41.35411799999997],
  [119.71788600000001, 41.354175999999974],
  [119.718061, 41.354201999999994],
  [119.71839700000001, 41.35420599999997],
  [119.71948800000004, 41.35414899999996],
  [119.71960199999997, 41.354156],
  [119.71999199999999, 41.35428200000004],
  [119.72017500000004, 41.35429399999997],
  [119.72044200000002, 41.35427899999997],
  [119.72083100000005, 41.35422500000003],
  [119.72096099999997, 41.35421699999999],
  [119.72102099999996, 41.354229000000004],
  [119.72105200000004, 41.354317],
  [119.72125800000003, 41.35494200000002],
  [119.72146400000003, 41.35532400000001],
  [119.72165500000001, 41.355572000000016],
  [119.72177699999999, 41.35567500000001],
  [119.72201299999998, 41.35583500000001],
  [119.72225000000005, 41.35596099999996],
  [119.72250899999997, 41.35605999999998],
  [119.72269999999997, 41.356113000000015],
  [119.72318099999998, 41.35620499999999],
  [119.723295, 41.356239000000045],
  [119.72338699999997, 41.356295999999965],
  [119.72358500000001, 41.35649100000003],
  [119.72373000000002, 41.35664699999997],
  [119.72398900000005, 41.356831],
  [119.72413400000005, 41.35691400000003],
  [119.72426399999996, 41.35693699999998],
  [119.72444700000001, 41.356960000000015],
  [119.72462300000001, 41.356982999999964],
  [119.72486700000003, 41.35707800000001],
  [119.72541599999998, 41.357299999999995],
  [119.72527099999998, 41.35739500000004],
  [119.72509599999998, 41.35752900000002],
  [119.72502700000004, 41.357620000000004],
  [119.72498900000001, 41.357693000000005],
  [119.72498900000001, 41.35776100000003],
  [119.72502700000004, 41.35787199999998],
  [119.72510400000002, 41.35798999999997],
  [119.72527099999998, 41.35815400000004],
  [119.72561400000002, 41.358413999999975],
  [119.72598799999997, 41.35863099999999],
  [119.72611799999999, 41.358760999999994],
  [119.72629399999998, 41.35888299999996],
  [119.72649200000002, 41.358959000000034],
  [119.72685099999997, 41.359024],
  [119.72716300000002, 41.35906999999998],
  [119.72744599999999, 41.35909300000002],
  [119.72759800000004, 41.35909300000002],
  [119.72765900000003, 41.35909999999998],
  [119.72774299999996, 41.359257],
  [119.727766, 41.35945500000004],
  [119.72781900000004, 41.35958499999996],
  [119.72791899999996, 41.35972999999997],
  [119.72809399999996, 41.359924000000035],
  [119.72816299999998, 41.36006199999999],
  [119.72818600000002, 41.36016499999999],
  [119.72820100000003, 41.36044299999999],
  [119.72826999999995, 41.360638000000044],
  [119.728308, 41.36076399999999],
  [119.72838399999998, 41.36084700000002],
  [119.72866600000003, 41.36101900000004],
  [119.72889499999998, 41.36126299999998],
  [119.72900200000004, 41.361343000000026],
  [119.72908599999997, 41.361365999999975],
  [119.72917, 41.361365999999975],
  [119.72929199999996, 41.361347],
  [119.72961299999997, 41.36124799999998],
  [119.72970400000004, 41.36124799999998],
  [119.72987900000004, 41.36126700000004],
  [119.73003900000005, 41.36131700000001],
  [119.73038299999999, 41.36149599999998],
  [119.73085599999996, 41.36178200000002],
  [119.73099300000001, 41.36189600000004],
  [119.73106199999995, 41.36199199999999],
  [119.73111599999999, 41.362137],
  [119.73113099999998, 41.362251000000015],
  [119.73113099999998, 41.36233900000001],
  [119.73112300000003, 41.36247599999998],
  [119.731069, 41.362578999999975],
  [119.73097800000002, 41.36278099999999],
  [119.73088700000004, 41.36307499999998],
  [119.73079499999997, 41.363364999999995],
  [119.73078700000002, 41.36363199999997],
  [119.73076499999998, 41.36373099999999],
  [119.730719, 41.36384999999998],
  [119.73058100000003, 41.364029000000045],
  [119.73041399999998, 41.36430700000004],
  [119.73029899999997, 41.36454400000002],
  [119.73024600000002, 41.364605000000005],
  [119.73014600000002, 41.36466200000002],
  [119.730032, 41.364699999999964],
  [119.72980299999996, 41.36475399999999],
  [119.72967300000005, 41.36483400000004],
  [119.729536, 41.36495600000001],
  [119.72945199999997, 41.365097000000034],
  [119.72936800000004, 41.36520000000003],
  [119.72932199999995, 41.365303000000026],
  [119.72925400000001, 41.365531999999966],
  [119.72921599999998, 41.36561599999999],
  [119.72919999999999, 41.36578400000003],
  [119.72929199999996, 41.36607700000003],
  [119.72933, 41.36625300000003],
  [119.72937599999999, 41.366310000000034],
  [119.72949, 41.36637100000002],
]
var anhuiLine4 = [
  [119.72969599999999, 41.366465999999974],
  [119.72987900000004, 41.36661900000002],
  [119.72991699999999, 41.366661000000036],
  [119.730017, 41.36677199999998],
  [119.73029899999997, 41.36697000000002],
  [119.73042100000004, 41.36708800000002],
  [119.730543, 41.367324999999994],
  [119.73055100000003, 41.36738599999998],
  [119.73061899999998, 41.36748899999998],
  [119.73068100000005, 41.36761500000001],
  [119.73079499999997, 41.367817000000024],
  [119.73084800000001, 41.36787800000001],
  [119.73091700000003, 41.36791199999998],
  [119.73099300000001, 41.36792799999997],
  [119.731405, 41.36790800000001],
  [119.73153500000001, 41.36792000000002],
  [119.73194000000004, 41.36807599999996],
  [119.73202299999997, 41.36809200000004],
  [119.73213000000004, 41.36810299999998],
  [119.73222200000001, 41.36810299999998],
  [119.73229799999999, 41.36808000000003],
  [119.732588, 41.367901000000046],
  [119.73266399999999, 41.36789300000001],
  [119.732794, 41.36790500000002],
  [119.73290099999997, 41.36793500000002],
  [119.73303800000002, 41.36801499999998],
  [119.73341199999997, 41.368347],
  [119.73352599999998, 41.368411999999964],
  [119.733771, 41.36851900000002],
  [119.73412200000001, 41.368610000000004],
  [119.73429700000001, 41.368698],
  [119.73489200000003, 41.36902599999996],
  [119.73522, 41.369236000000015],
  [119.73533400000001, 41.369308000000025],
  [119.735396, 41.36941100000002],
  [119.73547900000003, 41.36961400000003],
  [119.735556, 41.36978099999999],
  [119.73569299999997, 41.36993800000001],
  [119.73592199999999, 41.37013599999997],
  [119.73628000000004, 41.37040700000001],
  [119.73634899999998, 41.37047600000003],
  [119.73638700000001, 41.370521000000025],
  [119.73638700000001, 41.370613],
  [119.73634200000002, 41.370948999999996],
  [119.73636499999996, 41.37108199999999],
  [119.73641000000005, 41.37122000000004],
  [119.73647899999999, 41.37132999999999],
  [119.73655499999997, 41.37141799999999],
  [119.73664700000003, 41.371489999999994],
  [119.736738, 41.371529000000024],
  [119.73689099999997, 41.37156699999997],
  [119.73708199999996, 41.371562999999995],
  [119.73779899999995, 41.37151700000001],
  [119.73794399999996, 41.37154400000002],
  [119.73836299999999, 41.371628000000044],
  [119.73855399999998, 41.37164300000004],
  [119.73876800000002, 41.371684999999964],
  [119.73891999999998, 41.371684999999964],
  [119.73942400000003, 41.371669999999966],
  [119.73950799999996, 41.37168900000003],
  [119.73978299999997, 41.371768999999986],
  [119.73998799999997, 41.37179200000003],
  [119.74028600000003, 41.371811],
  [119.74056100000004, 41.37185999999997],
  [119.74092700000004, 41.371971],
  [119.74150699999998, 41.37220400000001],
  [119.74190399999996, 41.372421000000024],
  [119.74208700000001, 41.372513],
  [119.74226200000001, 41.37255899999998],
  [119.74236100000003, 41.37257399999998],
  [119.742643, 41.3726],
  [119.74295599999996, 41.37263099999999],
  [119.74310900000002, 41.372654000000026],
  [119.74317800000004, 41.37278400000004],
  [119.74324599999998, 41.373096000000004],
  [119.74333, 41.37332500000004],
  [119.74344500000001, 41.37351199999996],
  [119.74357400000002, 41.37366500000001],
  [119.74378000000002, 41.37383600000004],
  [119.74407000000004, 41.37419899999996],
  [119.74426799999999, 41.37444299999999],
  [119.744353, 41.37452300000004],
  [119.74443700000002, 41.374560999999986],
  [119.74485599999997, 41.374653000000045],
  [119.74508499999999, 41.37472099999999],
  [119.74529800000003, 41.37481299999996],
  [119.74564199999999, 41.37503500000003],
  [119.74568799999997, 41.37506500000003],
  [119.74596000000001, 41.37525500000003],
  [119.74603899999997, 41.37533599999998],
  [119.74606499999999, 41.37535299999997],
  [119.74614600000002, 41.37540400000001],
  [119.74624499999996, 41.375420000000005],
  [119.74657300000001, 41.375422999999984],
  [119.74774799999997, 41.375420000000005],
  [119.748152, 41.375420000000005],
  [119.74898400000001, 41.37550300000003],
  [119.74967799999997, 41.375640999999995],
  [119.74985399999997, 41.375706000000044],
  [119.74993699999999, 41.37580499999997],
  [119.74993699999999, 41.37588100000004],
  [119.749907, 41.375995999999965],
  [119.74984600000002, 41.37610200000003],
  [119.749716, 41.37624700000004],
  [119.74967000000002, 41.37632000000004],
  [119.749235, 41.376953000000015],
  [119.74909, 41.37725399999996],
  [119.74889999999999, 41.37770800000004],
  [119.74878499999998, 41.377894999999974],
  [119.748518, 41.37825799999999],
  [119.74833499999995, 41.378559000000024],
  [119.74820500000004, 41.378853000000014],
  [119.74817500000005, 41.37903199999999],
  [119.74815999999996, 41.379085000000025],
  [119.74798399999996, 41.37974500000001],
  [119.74789299999998, 41.380039],
  [119.74775500000003, 41.38038600000002],
  [119.74770199999999, 41.380565999999995],
  [119.74768699999998, 41.38073300000004],
  [119.74766400000004, 41.38085200000003],
  [119.74764899999995, 41.38112299999998],
  [119.74767900000003, 41.38127500000004],
  [119.74775500000003, 41.381439000000015],
  [119.747862, 41.381588],
  [119.748007, 41.381763],
  [119.74838899999999, 41.38207599999996],
  [119.74860999999997, 41.38228600000002],
  [119.74870200000004, 41.382453999999974],
  [119.74873999999998, 41.38261000000001],
  [119.74873999999998, 41.38272100000004],
  [119.74871700000004, 41.38283900000003],
  [119.74870200000004, 41.38293499999998],
  [119.74858700000003, 41.383190000000035],
  [119.74845700000002, 41.38336199999996],
  [119.748327, 41.383479999999956],
  [119.74809100000002, 41.38370500000001],
  [119.74793100000002, 41.38385799999997],
  [119.74788500000003, 41.383926],
  [119.747786, 41.384087],
  [119.747672, 41.38428099999997],
  [119.74742699999997, 41.38466600000003],
  [119.74732800000004, 41.38488400000004],
  [119.74725900000001, 41.385173999999964],
  [119.74719099999999, 41.385699999999964],
  [119.74719900000002, 41.385798999999984],
  [119.74723699999997, 41.38588300000001],
  [119.74742699999997, 41.38615399999996],
  [119.74753400000003, 41.38631399999996],
  [119.74761799999996, 41.38644800000004],
  [119.74767900000003, 41.386642000000016],
  [119.74775500000003, 41.387222000000044],
  [119.74775500000003, 41.387351999999964],
  [119.74772500000003, 41.38753100000003],
  [119.74756500000002, 41.38785200000003],
  [119.74742000000002, 41.38811900000001],
  [119.74725199999997, 41.388325],
  [119.74722099999998, 41.38838599999998],
  [119.74718300000004, 41.38853800000004],
  [119.747145, 41.38870599999999],
  [119.747145, 41.38881299999996],
  [119.74710699999996, 41.388939],
  [119.74703800000003, 41.389168000000026],
  [119.74702300000003, 41.38931300000004],
  [119.74704599999998, 41.38940400000001],
  [119.74712200000005, 41.38949200000001],
  [119.74740400000002, 41.389617999999956],
  [119.74787000000003, 41.389786],
  [119.74812200000001, 41.38983199999999],
  [119.748373, 41.38987300000001],
  [119.74851099999995, 41.389915000000016],
  [119.74856399999999, 41.389949999999985],
  [119.74863999999997, 41.390015000000034],
  [119.74880099999997, 41.39025899999997],
  [119.74902199999995, 41.39041899999997],
  [119.74910599999998, 41.39044899999997],
  [119.74957900000004, 41.39055600000003],
  [119.74978500000003, 41.39060999999997],
  [119.74983000000002, 41.39066699999998],
  [119.74984600000002, 41.39080800000001],
  [119.74982299999998, 41.39100299999998],
  [119.74979199999999, 41.391140000000036],
  [119.749747, 41.39132699999996],
  [119.74967799999997, 41.39139899999997],
  [119.74954800000005, 41.39147900000002],
  [119.74944900000003, 41.391517999999955],
  [119.74915900000002, 41.39164699999997],
  [119.74909099999998, 41.39170499999997],
  [119.74903700000004, 41.39177699999998],
  [119.74899900000001, 41.39186900000004],
  [119.74899099999998, 41.39210500000003],
  [119.74903700000004, 41.39229600000002],
  [119.74908300000003, 41.39238400000002],
  [119.74909099999998, 41.39250599999999],
  [119.74903700000004, 41.39263200000003],
  [119.74896900000002, 41.39272699999998],
  [119.74878499999998, 41.39288699999999],
  [119.74861800000002, 41.393089],
  [119.74845700000002, 41.393337],
  [119.74836600000003, 41.393428999999976],
  [119.74825899999998, 41.39350499999996],
  [119.74812200000001, 41.39358100000003],
  [119.74792299999997, 41.39365400000003],
  [119.747641, 41.39375299999996],
  [119.74745799999997, 41.39383300000001],
  [119.747351, 41.393893999999996],
  [119.74725199999997, 41.39400099999997],
  [119.74713, 41.39415699999999],
  [119.74693099999996, 41.394463],
  [119.74657999999997, 41.395080999999976],
  [119.74651999999998, 41.395237],
  [119.746458, 41.39548100000003],
  [119.74641999999996, 41.39556900000003],
  [119.74632899999999, 41.39577500000002],
  [119.74627499999995, 41.395854999999976],
  [119.746123, 41.39601100000001],
  [119.74603100000002, 41.39612999999999],
  [119.74596299999999, 41.39626299999998],
  [119.745932, 41.39640000000004],
  [119.74596299999999, 41.39667099999999],
  [119.74608499999995, 41.39698800000002],
  [119.74613799999999, 41.39712100000001],
  [119.74622899999997, 41.39727399999997],
  [119.74638999999996, 41.39747200000001],
  [119.74648100000003, 41.397560000000006],
  [119.74670200000003, 41.39778900000004],
  [119.746779, 41.397902999999964],
  [119.74715300000004, 41.39860900000003],
  [119.74722099999998, 41.39876599999996],
  [119.74719099999999, 41.398884000000045],
  [119.74716, 41.398960000000024],
  [119.74696199999995, 41.399227],
  [119.74687800000002, 41.399384000000026],
  [119.74675599999996, 41.399566999999976],
  [119.74671800000002, 41.39971900000003],
  [119.74674099999997, 41.399899000000005],
  [119.74674900000001, 41.399998000000025],
  [119.74667200000003, 41.400104],
  [119.746588, 41.40012399999997],
  [119.74632899999999, 41.400107999999975],
  [119.74613000000004, 41.400097000000045],
  [119.745947, 41.40011200000004],
  [119.74576399999995, 41.40018500000004],
  [119.74562699999998, 41.400279999999995],
  [119.74553500000002, 41.40036799999999],
  [119.74542800000005, 41.40044800000004],
  [119.74523800000004, 41.400703],
  [119.74511599999998, 41.400840999999964],
  [119.74508499999999, 41.40094800000002],
  [119.74508499999999, 41.40102799999998],
  [119.74516099999998, 41.40134000000004],
  [119.74519199999996, 41.40170700000003],
  [119.74518400000001, 41.40187499999998],
  [119.74515400000001, 41.402031000000015],
  [119.74503199999997, 41.40229799999999],
  [119.744925, 41.40247699999997],
  [119.74477999999998, 41.40266000000001],
  [119.74450499999996, 41.402884999999976],
  [119.744162, 41.403187],
  [119.743987, 41.403304999999996],
  [119.74382600000001, 41.40338900000002],
  [119.74376500000002, 41.40342000000001],
  [119.74348299999996, 41.40358000000001],
  [119.74338400000003, 41.40362899999998],
  [119.74328500000001, 41.403647999999954],
  [119.74316300000004, 41.403647999999954],
  [119.74304099999998, 41.403625000000005],
  [119.74292599999997, 41.40358000000001],
  [119.74284200000004, 41.40354199999998],
  [119.74269700000004, 41.40344199999996],
  [119.74236200000003, 41.40329],
  [119.74182000000003, 41.40314100000002],
  [119.74143800000004, 41.40306900000001],
  [119.741049, 41.40289699999999],
  [119.74084300000001, 41.40274800000001],
  [119.74063700000002, 41.40253800000004],
  [119.74056100000004, 41.40250399999998],
  [119.74044600000003, 41.40247699999997],
  [119.74025600000003, 41.40246600000003],
  [119.74003500000005, 41.402515],
  [119.73997299999998, 41.402530999999996],
  [119.73962199999998, 41.40268700000002],
  [119.73950799999996, 41.40272499999997],
  [119.73932500000001, 41.40276000000002],
  [119.73908800000002, 41.40274800000001],
  [119.73892099999998, 41.402751999999985],
  [119.73879099999996, 41.40276699999998],
  [119.73871399999999, 41.40277500000002],
  [119.73853899999999, 41.402844000000044],
  [119.738478, 41.40288199999999],
  [119.73843200000002, 41.40293099999996],
  [119.73842499999996, 41.402977000000035],
  [119.73840899999998, 41.40308000000003],
  [119.73843200000002, 41.403178999999966],
  [119.73847000000005, 41.40326299999999],
  [119.73856200000003, 41.40338900000002],
  [119.73864600000005, 41.40354499999996],
  [119.738669, 41.40367499999997],
  [119.738508, 41.40397300000002],
  [119.73846300000001, 41.40410600000001],
  [119.73843999999997, 41.40427800000003],
  [119.73839399999999, 41.404804000000034],
  [119.73834799999999, 41.40502600000002],
  [119.73827999999996, 41.40520100000002],
  [119.738188, 41.40535399999998],
  [119.73805799999998, 41.405525000000004],
  [119.737791, 41.405716],
  [119.73766199999999, 41.40577300000001],
  [119.73751699999998, 41.405864999999984],
  [119.73733400000003, 41.405979],
  [119.73708900000001, 41.40614299999998],
  [119.73694499999999, 41.406296000000026],
  [119.73670800000001, 41.40669300000001],
  [119.73660099999995, 41.40682999999998],
  [119.73651700000002, 41.406899],
  [119.736388, 41.406956000000015],
  [119.73603700000001, 41.40707400000001],
  [119.73594500000003, 41.407131000000014],
  [119.735907, 41.40719599999998],
  [119.73589900000005, 41.40734099999998],
  [119.73589900000005, 41.407577999999965],
  [119.735877, 41.40772299999997],
  [119.73583799999997, 41.40781800000001],
  [119.73574699999999, 41.40793200000003],
  [119.735586, 41.408112],
  [119.73535000000001, 41.40825300000004],
  [119.73522, 41.40833299999999],
  [119.73505200000004, 41.408417000000014],
  [119.73480099999996, 41.408489000000024],
  [119.734648, 41.40852000000001],
  [119.73385500000003, 41.40863400000003],
  [119.73363299999995, 41.408661000000045],
  [119.73338199999998, 41.408741],
  [119.733084, 41.40892800000002],
  [119.73290099999997, 41.40900000000003],
  [119.732649, 41.40907300000003],
  [119.73224500000005, 41.40913400000001],
  [119.73190100000001, 41.409171999999955],
  [119.73184100000002, 41.409237000000005],
  [119.73184100000002, 41.40930600000003],
  [119.73186299999998, 41.40937800000004],
  [119.73196299999998, 41.40951899999998],
  [119.73210000000005, 41.40966799999996],
  [119.73231399999999, 41.40983200000003],
  [119.732458, 41.409908000000016],
  [119.73263399999999, 41.40996600000002],
  [119.73269499999998, 41.410034000000046],
  [119.73269499999998, 41.41008400000001],
  [119.73266499999998, 41.41022099999998],
  [119.73252700000002, 41.41060700000003],
  [119.73236700000001, 41.41090799999998],
  [119.73225999999995, 41.411071999999955],
  [119.73220700000002, 41.41119799999999],
  [119.73219200000001, 41.41130099999999],
  [119.73220700000002, 41.41162900000003],
  [119.73216899999997, 41.411841999999986],
  [119.73203100000002, 41.41222],
  [119.73199299999997, 41.412449000000024],
  [119.73200799999998, 41.412533000000046],
  [119.732092, 41.412677999999964],
  [119.73225999999995, 41.412848999999994],
  [119.73251999999998, 41.41309000000004],
  [119.73259600000004, 41.41323900000002],
  [119.73260599999999, 41.41327499999998],
  [119.73265700000003, 41.41345600000003],
  [119.73265700000003, 41.41363100000004],
  [119.732649, 41.41367700000002],
  [119.73259600000004, 41.413746000000046],
  [119.73252700000002, 41.41384499999998],
  [119.73238200000002, 41.41397899999996],
  [119.73222200000001, 41.41405099999997],
  [119.73189399999995, 41.41413100000002],
  [119.73152799999997, 41.41418099999998],
  [119.731245, 41.414207999999995],
  [119.73038299999999, 41.414192],
  [119.73029200000002, 41.41421099999997],
  [119.73007000000003, 41.41431399999997],
  [119.72988000000004, 41.414428999999984],
  [119.729651, 41.41459299999996],
  [119.72955900000002, 41.414654000000034],
  [119.72943699999998, 41.41474200000003],
  [119.72930000000001, 41.41488700000004],
  [119.72923099999998, 41.41502400000001],
  [119.72920800000003, 41.415157],
  [119.72921599999998, 41.415226000000025],
  [119.72939099999998, 41.415626999999986],
  [119.72941400000002, 41.415741000000004],
  [119.72941400000002, 41.415794000000034],
  [119.72938400000002, 41.41586299999997],
  [119.72919300000004, 41.41611099999997],
  [119.72909400000002, 41.416294000000015],
  [119.72909052310587, 41.416337026565174],
]

var coordinates = []
coordinates.push({
  coords: anhuiLine1,
  lineStyle: {},
})
coordinates.push({
  coords: anhuiLine2,
  lineStyle: {},
})
coordinates.push({
  coords: anhuiLine3,
  lineStyle: {},
})
coordinates.push({
  coords: anhuiLine4,
  lineStyle: {},
})

var coordinatesdata = anhuiLine1.concat(anhuiLine2, anhuiLine3, anhuiLine4)
var mapconvertData = function () {
  var res = []
  for (var i = 0; i < coordinatesdata.length - 1; i++) {
    var fromCoord = coordinatesdata[i]
    var toCoord = coordinatesdata[i + 1]
    if (fromCoord && toCoord) {
      res.push([
        {
          coord: fromCoord,
        },
        {
          coord: toCoord,
        },
      ])
    }
  }
  console.log(res)
  return res
}

export { mapdata, geoCoordMap, coordinates }
